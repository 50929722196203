import React from "react"
import NoSidebarLayout from "../layout/NoSidebarLayout"
import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import Fade from "react-reveal/Fade"
import { blogBanner } from "../images"
import styled from "styled-components"
import SEO from "../components/seo"

const StyledImg = styled.img`
  max-width: 60%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;

  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: 90%;
  @media (min-width: 2000px) {
    max-width: 50%;
  }
`

const HeaderTitle = styled.h1`
  text-align: center;
  font-size: calc(20px + 1.5vw);
`

const DateH1 = styled.h1`
  font-size: 15px;
  color: gray;
  text-align: center;
`

export default ({ data }) => {
  const page = data.allWpPage.edges[0].node
  const content = page.content
  const title = page.title.slice(9)
  const processedData = ReactHtmlParser(content)
  return (
    <NoSidebarLayout pageTitle={title} background={blogBanner}>
      <SEO
        title={title}
        description={page.seo.opengraphDescription}
      />
      <div className="blog-post">
        <Header>
          <HeaderText>
            {processedData.map((data, index) => {
              return (
                <Fade>
                  <div className="blog-post">{data}</div>
                </Fade>
              )
            })}
          </HeaderText>
          <br />
        </Header>
      </div>
    </NoSidebarLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          seo {
            opengraphDescription
            opengraphTitle
          }
        }
      }
    }
  }
`
